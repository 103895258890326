// import hljs from 'highlight.js';

import hljs from 'highlight.js/lib/core';
import xml from 'highlight.js/lib/languages/xml';
hljs.registerLanguage('xml', xml);

import 'highlight.js/styles/a11y-dark.css';


document.addEventListener("turbo:load", function() {
  document.querySelectorAll('pre code').forEach((el) => {
    hljs.highlightBlock(el);
  });
})