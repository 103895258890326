import TurbolinksAdapter from 'vue-turbolinks';
import Vue from 'vue/dist/vue.esm';
import Vuex from 'vuex'

import App from '../app.vue';

Vue.use(Vuex)
Vue.use(TurbolinksAdapter)

const store = new Vuex.Store({
  state: {
    events: {},
    blockedTimes: {},
    timeFrom: '',
    timeTo: ''
  },
  getters: {
    events: state => {
        return [ ...state.events, ...state.blockedTimes ]
    }
  },
  mutations: {},
  actions: {}
});

document.addEventListener('turbo:load', () => {
  const element = document.getElementById('appointments');

  if ( element != undefined ) {
    store.state.events = JSON.parse(element.dataset.events)
    store.state.blockedTimes = JSON.parse(element.dataset.blockedTimes)
    store.state.timeFrom = JSON.parse(element.dataset.timeFrom)
    store.state.timeTo = JSON.parse(element.dataset.timeTo)

    const app = new Vue({
      el: element,
      store: store,
      template: '<App/>',
      components: { App }
    })
  }
})
