import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static values = {
        dismissAfter: Number
    }
    static targets = ['content']

    // Sets the popover offset using Stimulus data map objects.
    initialize() {
        this.contentTarget.setAttribute(
            'style',
            `transform:translate(${this.data.get('translateX')}, ${this.data.get('translateY')});`,
        )
    }

    // Show the popover
    mouseOver() {
        this.contentTarget.classList.remove('hidden')
    }
    // Hide the popover
    mouseOut() {
        this.contentTarget.classList.add('hidden')
    }
    // Toggle the popover on demand
    toggle() {
        if (this.contentTarget.classList.contains('hidden')) {
            this.contentTarget.classList.remove('hidden')

            if (this.hasDismissAfterValue) {
                setTimeout(() => {
                    this.contentTarget.classList.add('hidden')
                }, this.dismissAfterValue)
            }
        } else {
            this.contentTarget.classList.add('hidden')
        }
    }
}