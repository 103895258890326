import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    reset(event) {
        this.element.reset()
        this.removeErrorClass(event)
    }

    submit() {
        this.element.submit()
    }

    removeErrorClass(event) {
        if (!event.detail.formSubmission.result.success) return

        const inputWrappers = this.element.querySelectorAll(".field-with-errors")
        inputWrappers.forEach(wrapper => {
            wrapper.classList.remove("field-with-errors")
        })
    }

}
