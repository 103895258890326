import ColorPicker from 'stimulus-color-picker'
import '@simonwep/pickr/dist/themes/classic.min.css'
import Pickr from '@simonwep/pickr'

export default class extends ColorPicker {
    initialize () {
        super.initialize()
        this.onChange = this.onChange.bind(this)
    }

    connect () {
        this.picker = Pickr.create({
            el: this.buttonTarget,
            theme: this.themeValue,
            default: this.inputTarget.value,
            lockOpacity: true,
            defaultRepresentation: 'HEX',
            comparison: false,
            swatches: this.swatches,
            components: this.componentOptions
        })

        this.picker.on('save', this.onSave)
        this.picker.on('change', this.onChange)
    }

    onChange(color) {
        this.inputTarget.value = null

        if (color) {
            this.inputTarget.value = color.toHEXA().toString()
        }
    }

    get componentOptions () {
        return {
            preview: true,
            opacity: false,
            hue: true,

            interaction: {
                hex: true,
                rgba: true,
                hsla: false,
                hsva: false,
                cmyk: false,
                input: true,
                clear: false,
                save: false,
            }
        }
    }

    get swatches () {
        return [
            '#CBE4F9',
            '#CDF5F6',
            '#EFF9DA',
            '#F9EBDF',
            '#F9D8D6',
            '#D6CDEA',
        ]
    }
}