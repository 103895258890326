// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js or *_controller.ts.

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import Dropdown from 'stimulus-dropdown'
import Notification from 'stimulus-notification'
import Flatpickr from 'stimulus-flatpickr'

const application = Application.start()
const context = require.context("controllers", true, /_controller\.(js|ts)$/)
application.load(definitionsFromContext(context))

require("flatpickr/dist/flatpickr.css")
application.register('flatpickr', Flatpickr)
application.register('dropdown', Dropdown)
application.register('notification', Notification)
